:global {
  font-family: -apple-system, BlinkMacSystemFont, Inter, Roboto, Helvetica, Arial, sans-serif;
}

.rdrWeekDay {
  text-transform: uppercase;
}

.rdrMonthPicker {
  select {
    text-transform: capitalize;
  }
}

.rdrMonthName {
  text-transform: capitalize;
}