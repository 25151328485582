div[disabled]
{
  pointer-events: none;
  opacity: 0.7;
}

div[aria-disabled=true]
{
  pointer-events: none;
  opacity: 0.5;
}